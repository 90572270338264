import { FC, useEffect, useState } from 'react'
import Parcel from 'assets/images/parcel.svg'
import CN from 'classnames'

import { dateToAEST } from 'utils'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface OrderStatusProgressBarProps {
  className?: string | undefined
  currentStatus?:
    | 'Order-Received'//'Received'   
    | 'Pick-pack-in-progress'//Picking-Progress'
    | 'To-be-consigned'//'Picking-Completed'
    | 'Shipped'  //Shiped   
    | ''
    | undefined

  [x: string]: any
}

export const OrderStatusProgressBar: FC<OrderStatusProgressBarProps> = ({
  currentStatus,
  partnerOrderHistory,
  className,
  ...restProps
}: OrderStatusProgressBarProps) => {
  const [dateList, setDateList] = useState({
    OrderReceived: '',  
    PickPackinprogress: '',
    ToBeConsigned: '',
    Closed: '',    
    CancelConsigned: '',    
  })

  const OrderStatusProgressBarClassnames = CN(
    'border-[1px] border-N-300 rounded-lg pb-1 pt-1 w-full bg-N-50',
    className,
  )

  const containerClassnames = CN(
    'flex items-center mt-4 flex justify-center items-center w-full mb-[48px]',
  )

  useEffect(() => {
    const latestReceived = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          (capitalizeFirstLetter(item?.status) === 'Received' || capitalizeFirstLetter(item?.status) === 'Confirmed'
          || capitalizeFirstLetter(item?.status) === 'Exception' || capitalizeFirstLetter(item?.status) === 'Failed'
           || capitalizeFirstLetter(item?.status) === 'BackOrder' )&&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    // const initialConfirmed = partnerOrderHistory?.find(
    //   (item: any) => capitalizeFirstLetter(item?.status) === 'Confirmed',
    // )

    const latestOpen = partnerOrderHistory?.reduce((acc: any, item: any) => {
      if (
        capitalizeFirstLetter(item?.status) === 'Open' &&
        (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
      ) {
        return item
      }
      return acc
    }, null)

    const latestToBeConsigned = partnerOrderHistory?.reduce(      
      (acc: any, item: any) => {
        if (          
          capitalizeFirstLetter(item?.status) === 'Tobeconsigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) { 
          return item
        }
        return acc
      },
      null,
    )

    const latestConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          (capitalizeFirstLetter(item?.status) === 'Closed' || capitalizeFirstLetter(item?.status) === 'Partial-consigned') &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestPartialConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Partial-consigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestCancelConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          item?.status === 'CANCELLED-CONSIGNED' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    setDateList({
      OrderReceived: latestReceived
        ? dateToAEST(latestReceived?.updatedOn || '')
        : '',
      
      PickPackinprogress: latestOpen ? dateToAEST(latestOpen?.updatedOn || '') : '',

      ToBeConsigned: latestToBeConsigned
        ? dateToAEST(latestToBeConsigned?.updatedOn || '')
        : '',

      Closed: latestConsigned
        ? dateToAEST(latestConsigned?.updatedOn || '')
        : '',
     
      CancelConsigned: latestCancelConsigned
        ? dateToAEST(latestCancelConsigned?.updatedOn || '')
        : '',
    })
  }, [partnerOrderHistory])

  return (
    <div className={OrderStatusProgressBarClassnames} {...restProps}>
      <div className={containerClassnames}>
        {/* Received Section */}
        <div
          className={CN(
            'relative rounded-full flex justify-center items-center',
            {
              'w-10 h-10 bg-P-600':
                currentStatus === 'Order-Received' ||                
                currentStatus === 'Pick-pack-in-progress' ||
                currentStatus === 'To-be-consigned' ||
                currentStatus === 'Shipped' ,//||
                //currentStatus === 'Partial-consigned'
              'w-[20px] h-[20px] bg-N-300': currentStatus !== 'Order-Received',
            },
          )}>
          {currentStatus === 'Order-Received' && <img src={Parcel} alt='Parcel' />}

          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
              {
                'pt-10': currentStatus === 'Order-Received',
                'pt-8': currentStatus !== 'Order-Received',
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800': currentStatus === 'Order-Received',
                'font-regular text-N-400':
                  currentStatus !== 'Order-Received' &&
                  (currentStatus === undefined || currentStatus === ''),
                'font-regular text-N-700':
                  currentStatus !== 'Order-Received' &&
                  currentStatus !== undefined &&
                  currentStatus !== '',
              })}>
              Order Received
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.OrderReceived}
            </div>
          </div>
        </div>       

        {/* Picking Progress Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'Pick-pack-in-progress' ||
              currentStatus === 'To-be-consigned' ||
              currentStatus === 'Shipped',
            'bg-N-300': currentStatus !== 'Pick-pack-in-progress',
          })}></div>

        <div
          className={CN(
            'relative rounded-full bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10': currentStatus === 'Pick-pack-in-progress',
              'w-[20px] h-[20px]': currentStatus !== 'Pick-pack-in-progress',
            },
            {
              'bg-P-600':
                currentStatus === 'Pick-pack-in-progress' ||
                currentStatus === 'To-be-consigned' ||
                currentStatus === 'Shipped',
              'bg-N-300': currentStatus !== 'Pick-pack-in-progress',//'Confirmed'
            },
          )}>
          {currentStatus === 'Pick-pack-in-progress' && (
            <img src={Parcel} alt='Parcel' />
          )}
          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[190px]',
              {
                'pt-11': currentStatus === 'Pick-pack-in-progress',
                'pt-8': currentStatus !== 'Pick-pack-in-progress',
              },
            )}>
            <div
              className={CN('text-Small', {
                'font-bold text-N-800': currentStatus === 'Pick-pack-in-progress',
                'font-regular text-N-400':
                  currentStatus !== 'Pick-pack-in-progress' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Order-Received'),
                'font-regular text-N-700':
                  currentStatus !== 'Pick-pack-in-progress' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Order-Received' ,
              })}>
              Pick & Pack in progress
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.PickPackinprogress}
            </div>
          </div>
        </div>

        {/* Picking Completed Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'To-be-consigned' ||
              currentStatus === 'Shipped',
            'bg-N-300': currentStatus !== 'To-be-consigned',
          })}></div>

        <div
          className={CN(
            'rounded-full relative bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10': currentStatus === 'To-be-consigned',
              'w-[20px] h-[20px]': currentStatus !== 'To-be-consigned',
            },
            {
              'bg-P-600':
                currentStatus === 'To-be-consigned' ||
                currentStatus === 'Shipped',
              'bg-N-300': currentStatus !== 'Pick-pack-in-progress',  //'Confirmed',
            },
          )}>
          {currentStatus === 'To-be-consigned' && (
            <img src={Parcel} alt='Parcel' />
          )}
          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
              {
                'pt-11': currentStatus === 'To-be-consigned',
                'pt-8': currentStatus !== 'To-be-consigned',
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800': currentStatus === 'To-be-consigned',
                'font-regular text-N-400':
                  currentStatus !== 'To-be-consigned' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Order-Received' ||
                    currentStatus === 'Pick-pack-in-progress'),
                'font-regular text-N-700':
                  currentStatus !== 'To-be-consigned' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Order-Received' &&
                  currentStatus !== 'Pick-pack-in-progress',
              })}>
              To be consigned
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.ToBeConsigned}
            </div>
          </div>
        </div>

        {/* Shipped Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'Shipped',
            'bg-N-300': !(
              currentStatus == 'Shipped'
            ),
          })}></div>

        <div
          className={CN(
            'rounded-full relative bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10':
                currentStatus === 'Shipped',
              'w-[20px] h-[20px]': !(
                currentStatus === 'Shipped'
              ),
            },
            {
              'bg-P-600':
                currentStatus === 'Shipped',
              'bg-N-300': !(
                currentStatus === 'Shipped'
              ),
            },
          )}>
          {(currentStatus === 'Shipped'
          ) && (
            <img src={Parcel} alt='Parcel' />
          )}

          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
              {
                'pt-11':
                  currentStatus === 'Shipped',
                'pt-8': !(
                  currentStatus === 'Shipped'
                ),
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800':
                  currentStatus === 'Shipped',
                'font-regular text-N-400':
                  currentStatus !== 'Shipped' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Order-Received' ||
                    currentStatus === 'Pick-pack-in-progress' ||
                    currentStatus === 'To-be-consigned'),
                'font-regular text-N-700':
                  currentStatus !== 'Shipped' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Order-Received' &&
                  currentStatus !== 'Pick-pack-in-progress' &&
                  currentStatus !== 'To-be-consigned',
              })}>             
                Shipped
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.Closed}              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OrderStatusProgressBar.defaultProps = {
  className: undefined,
  currentStatus: undefined,
}

export default OrderStatusProgressBar
