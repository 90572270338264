/* eslint-disable */
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable,
  useSortBy,
  useMountedLayoutEffect,
} from 'react-table'
import { UITableTDCell, UITableTHCell } from './UITableParts'
import CN from 'classnames'
import './UITable.scss'
import { Button, Checkbox, EmptyState, PageLoader } from 'components/atoms'

export interface UITableProps {
  onChangePage?: any
  defaultSortBy?: any
  allowRowSelection?: boolean
  isPaginated?: boolean
  isFetching?: boolean
  selectedRowsIds?: any
  footerRow?: React.ReactNode
  [x: string]: any
}
export const UITable: FC<UITableProps> = ({
  className,
  columns: userColumns,
  data,
  isLoading,
  isFetching,
  handleSortByChange,
  isManualSortBy,
  isSorted,
  onChangeSelectedRowsId,
  selectedRowsIds,
  allowRowSelection,
  defaultSortBy,
  isPaginated,
  disableInnerLoading,
  disableInnerNoItemFound,
  forceSelectedRowIndexes,
  hiddenColumns,
  isManualSort,
  hasFooter,
  paginationVariables,
  setPaginationVariables,
  totalRowCount,
  onRowSelect,
  pageNumber,
  setPageNumber,
  hasCheckBox,
  isExpandable,
  isActiveRowHighlights,
  isHeader,
  isFilterEnabled,
  filterExtraButton,
  filterContent,
  fixedHighlightBody,
  onChange,
  onDeselectRowId,
  onSelectAllRows,
  footerRow,
  ...restProps
}: UITableProps) => {
  const UITableClasses = CN(`ui-table bg-white`, className)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    onRowSelect: onRowSelectTable,
    pageOptions,
    setHiddenColumns,
    toggleRowSelected,
    toggleAllRowsSelected,
    state: { pageIndex, selectedRowIds },
  }: any = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        selectedRowIds: selectedRowsIds,
      },
      // defaultCanSort: true,
      manualSortBy: isManualSortBy,
    } as any,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      allowRowSelection &&
        hooks.visibleColumns.push((cols) => [
          {
            id: 'selection',
            customWidth: '44px',
            isSortable: false,
            Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
              <Checkbox
                helperText=''
                labelText=''
                {...getToggleAllPageRowsSelectedProps()}
                onClick={(e: any) => {
                  onSelectAllRows(e.target.checked)
                }}
              />
            ),
            Cell: ({ row }: any) => (
              <Checkbox
                helperText=''
                labelText=''
                {...row?.getToggleRowSelectedProps()}
                onClick={(e: any) => {
                  !e.target.checked && onDeselectRowId(row?.original?.id)
                  e.target.checked && onDeselectRowId(null)
                }}
              />
            ),
          },
          ...cols,
        ])
    },
  )
  const filterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    isPaginated && toggleAllRowsSelected(false)
  }, [pageNumber])

  useEffect(() => {
    if (userColumns && userColumns.length > 0) {
      setHiddenColumns(
        userColumns
          ?.filter((column: any) => !column?.isVisible)
          ?.map((column: any) => column?.accessor),
      )
    }
  }, [userColumns])

  useEffect(() => {
    data && data.length > 0 && toggleAllRowsSelected(false)

    data &&
      data.length > 0 &&
      forceSelectedRowIndexes &&
      forceSelectedRowIndexes.length > 0 &&
      forceSelectedRowIndexes.map((idx: any) => {
        toggleRowSelected(idx.toString(), true)
      })
  }, [forceSelectedRowIndexes, data])

  /* Pass selected rows to outside */
  useMountedLayoutEffect(() => {
    if (selectedFlatRows?.length > 0) {
      const filterOriginals = selectedFlatRows?.map((d: any) => d?.original)
      onRowSelect && onRowSelect(filterOriginals)
    } else {
      onRowSelect && onRowSelect([])
    }
  }, [])

  useEffect(() => {
    if (selectedFlatRows?.length > 0) {
      const filterOriginals = selectedFlatRows?.map((d: any) => d?.original)
      onRowSelect && onRowSelect(filterOriginals)
    }
  }, [selectedFlatRows])

  //const [pageNumber, setPageNumber] = useState(1)

  const [showFilters, setShowFilters] = useState(false)
  const [pageNumberVariables, setPageNumberVariables] = useState({
    start:
      Number(paginationVariables.take) * Number(pageNumber) -
      Number(paginationVariables.take) +
      1,
    end:
      Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
        ? totalRowCount
        : Number(paginationVariables.take) * Number(pageNumber),
  })

  /** Pagination Functions */
  useEffect(() => {
    setPageNumberVariables({
      ...pageNumberVariables,
      start:
        Number(paginationVariables.take) * Number(pageNumber) -
        Number(paginationVariables.take) +
        1,
      end:
        Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(paginationVariables.take) * Number(pageNumber),
    })
  }, [totalRowCount, pageNumber, paginationVariables])

  useEffect(() => {
    paginationVariables.search && setPageNumber(1)
  }, [paginationVariables.search])

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(paginationVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  // if (data.length === 0 && !isLoading) return <PlaceHolder />

  const PlaceholderComponent = () => (
    <div className='flex items-center justify-center flex-col'>
      <EmptyState />
    </div>
  )

  return (
    <div
      className={CN(
        `h-full w-full border border-N-300 ui-table__wrapper rounded-t-md overflow-y-auto`,
        {
          'bg-white': data?.length > 0,
        },
      )}>
      <div className={CN(UITableClasses, 'flex')} {...restProps}>
        <table {...getTableProps()}>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} className=''>
                {headerGroup.headers.map((column: any, idx: any) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        if (column.id !== 'selection' && column.isSortable) {
                          column.toggleSortBy()
                          toggleAllRowsSelected(false)
                          let sort
                          {
                            column.isSorted
                              ? column.isSortedDesc
                                ? (sort = '')
                                : (sort = 'desc')
                              : (sort = 'asc')
                          }
                          isSorted &&
                            isManualSortBy &&
                            setPaginationVariables({
                              ...paginationVariables,
                              sortColumnName:
                                sort === '' ? '' : column.forSorting,
                              sortDirection: sort,
                            })
                        }
                      }}
                      className={CN(
                        {
                          'cursor-pointer select-none': column.isSortable,
                        },
                        column?.headerClassName,
                      )}
                      width={column.customWidth}
                      style={{ width: column?.customWidth }}>
                      <UITableTHCell
                        className={column.commonClassName}
                        icon={
                          column.isSortable
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? 'ri-arrow-down-s-line'
                                : 'ri-arrow-up-s-line'
                              : 'ri-arrow-up-down-line'
                            : ''
                        }
                        columnId={column.id}
                        headerClassName={column.headerClassName}
                        arrayPosition={idx}
                        arrayLength={headerGroup?.headers?.length}
                        isSorted={column?.isSorted}
                        hasFilter={column.hasFilter}>
                        {column.render('Header')}
                        {column.hasFilter && (
                          <div ref={filterRef}>{column.dropdownFilter}</div>
                        )}
                      </UITableTHCell>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          {(isFetching || isLoading) && !disableInnerLoading && (
            <div
              className={CN(
                'absolute top-[73px] left-0 flex items-center justify-center bg-S-500 bg-opacity-20 w-full h-[calc(100%-100px)] cursor-not-allowed z-[3]',
                {
                  'h-[calc(100%-132px)]': isPaginated,
                  'h-[calc(100%-85px)] !top-[60px]': !isPaginated,
                },
              )}>
              <PageLoader />
            </div>
          )}
          {data.length === 0 && !disableInnerNoItemFound && !isLoading && (
            <div
              className={CN(
                'absolute top-[73px] left-0 flex items-center justify-center bg-white w-full h-[calc(100%-106px)] z-[2] border !border-t-0 !border-b-0 border-S-200',
                {
                  'h-[calc(100%-132px)]': isPaginated,
                  'h-[calc(100%-85px)] !top-[60px]': !isPaginated,
                },
              )}>
              <PlaceholderComponent />
            </div>
          )}
          <tbody {...getTableBodyProps()} className='relative'>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <tr
                  key={i}
                  className={CN('border-b border-S-100 hover:bg-N-50', {
                    'bg-white': row?.depth === 0,
                    'bg-S-300': row?.depth === 1,
                    'bg-S-100': row?.depth === 2,
                  })}
                  {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        className={CN('relative text-x-small w-fit', {
                          // 'bg-N-200': Object.keys(selectedRowIds).includes(
                          //   i.toString(),
                          // ),
                        })}
                        {...cell.getCellProps()}>
                        {UITableTDCell(cell)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {footerRow && (
        <tfoot>
          {footerRow} {/* Render footer row */}
        </tfoot>
      )}
        </table>
      </div>

      {hasFooter && (
        <div className='absolute bottom-0 left-0 bg-white flex items-center justify-between w-full rounded-b px-4 py-[8px] border !border-t-0 border-S-200'>
          <div className='flex items-center w-1/2'>
            <span className='text-S-900 font-regular text-small '>
              Items per page
            </span>
            <div className='border border-S-300 rounded-md py-[5px] pr-[10px] ml-2 h-[40px]'>
              <select
                className='outline-none px-2 '
                value={paginationVariables?.take || 10}
                onChange={(e) => {
                  setPageNumber(1)
                  setPaginationVariables({
                    ...paginationVariables,
                    take: e.target.value,
                  })
                }}>
                {[10, 20, 30, 50].map((pageSizeValue) => (
                  <option key={pageSizeValue} value={pageSizeValue}>
                    {pageSizeValue}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex items-center justify-center '>
            <Button
              iconOnly
              appearance='secondary'
              disabled={pageNumber <= 1}
              size='md'
              onClick={() => {
                setPageNumber(1)
              }}>
              <i className='ri-arrow-left-line text-[24px] leading-none' />
            </Button>
            <span className='mx-3'>
              {pageNumberVariables.start} - {pageNumberVariables.end} of{' '}
              {totalRowCount}
            </span>
            <Button
              className='mr-3'
              iconOnly
              size='md'
              appearance='secondary'
              disabled={pageNumber <= 1}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}>
              <i className='ri-arrow-left-s-line text-[24px] leading-none' />
            </Button>
            <Button
              iconOnly
              appearance='secondary'
              size='md'
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              disabled={
                Number(pageNumber) * Number(paginationVariables.take) >=
                Number(totalRowCount)
              }>
              <i className='ri-arrow-right-s-line text-[24px] leading-none' />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
UITable.defaultProps = {
  isManualSortBy: false,
  handleSortByChange: undefined,
  isPaginated: true,
  isSorted: true,
  disableInnerLoading: false,
  disableInnerNoItemFound: false,
  selectedRowsIds: {},
  isManualSort: false,
  hasFooter: true,
  hasCheckBox: true,
  isExpandable: false,
  isActiveRowHighlights: false,
  isHeader: false,
  isFilterEnabled: false,
  filterExtraButton: undefined,
  filterContent: undefined,
  fixedHighlightBody: false,
}
export default UITable
