import {FC} from 'react'

export const ComingSoon: FC = () => {
    return <>
        <div className='flex flex-col h-fit w-full bg-white rounded-lg px-3 py-3 shadow-md'>
            <div className='font-semibold text-heading-3 text-N-800 mt-2 pb-2'>
                Stay tuned! Inbound shipment updates will be here shortly.
            </div>
        </div>
    </>
}

ComingSoon.defaultProps = {
    className: undefined,
}

export default ComingSoon
