import {
  BACKORDER,//
  CANCELLED,
  CLOSED,
  CONFIRMED,
  EXCEPTION,//
  FAILED,//
  ONHOLD,
  OPEN,
  PARTIALCONSIGNED,//
  RECEIVED,//
  TOBECONSIGNED,
} from 'static-data/orderStatus'

export const orderStatusNameConversion = (status: string | undefined) => {

  if (!status) return ''

  const orderReceivedStatuses = [CONFIRMED, EXCEPTION, BACKORDER, RECEIVED, FAILED]
  const shippedStatuses = [CLOSED, PARTIALCONSIGNED]
  const pickAndPackStatuses = [OPEN]
  const onHoldStatuses = [ONHOLD]
  const cancelledStatuses = [CANCELLED]
  const tobeConsignedStatuses = [TOBECONSIGNED]

  if (orderReceivedStatuses.includes(status)) return 'Order received'
  if (shippedStatuses.includes(status)) return 'Shipped'
  if (pickAndPackStatuses.includes(status)) return 'Pick & pack in progress'
  if (onHoldStatuses.includes(status)) return 'On hold'
  if (cancelledStatuses.includes(status)) return 'Cancelled'
  if (tobeConsignedStatuses.includes(status)) return 'To be consigned'

  return ''
}

export const orderStatusNameConversionNew = (status: string | undefined) => {
  let orderStatus = ''

  switch (status) {
    case RECEIVED:
      orderStatus = 'Received'
      break
    case CONFIRMED:
      orderStatus = 'Confirmed'
      break
    case FAILED:
      orderStatus = 'Failed'
      break
    case EXCEPTION:
      orderStatus = 'Error'
      break
    case OPEN:
      orderStatus = 'Picking Progress'
      break
    case TOBECONSIGNED:
      orderStatus = 'Pick Completed'
      break
    case PARTIALCONSIGNED:
      orderStatus = 'Partial Consigned'
      break
    case ONHOLD:
      orderStatus = 'On Hold'
      break
    case CANCELLED:
      orderStatus = 'Cancelled'
      break
    case CLOSED:
      orderStatus = 'Closed'
      break
    case BACKORDER:
      orderStatus = 'Error'
  }

  return orderStatus
}

export default orderStatusNameConversion
