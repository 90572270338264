import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { useGetPartnerOrderDetailsById } from 'framework/api/methods'
import {
  BACKORDER,
  CANCELLED,
  CLOSED,
  CONFIRMED,
  EXCEPTION,
  FAILED,
  ONHOLD,
  OPEN,
  PARTIALCONSIGNED,
  RECEIVED,
  TOBECONSIGNED} from 'static-data/orderStatus'
import { setBackFunction } from 'store/reducers/layout/layoutSlice'

import { PageLoader } from 'components/atoms'
import { UITable } from 'components/common/UITable'
import { Toast } from 'components/molecules'

import { OrderInformationSection } from './OrderInformationSection'
import { OrderLineListColumn } from './OrderLineListColumn'
import { OrderStatusProgressBar } from './OrderStatusProgressBar'

// Define the OrderLine interface
interface OrderLine {
  partnerSku: string;
  qty: number | null;
  shippedQTY: number | null;
}

export interface OrderDetailsProps {
  className?: string | undefined
  [x: string]: any
}

export const OrderDetails: FC<OrderDetailsProps> = ({
  ...restProps
}: OrderDetailsProps) => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  const [orderDetails, setOrderDetails] = useState<any>({})
  const [searchParams] = useSearchParams()
  const notify = (props: any) => Toast(props)

  const [paginationVariables, setPaginationVariables] = useState<any>({
    take: 100,
  })

  const [orderLineTableColumn, setOrderLineTableColumn] = useState<any>([])
  const [warehouseDetails, setWarehouseDetails] = useState<any>({})
  const [shippingInformation, setShippingInformation] = useState<any>({})

  useEffect(() => {
    callGetPartnerOrderDetailsById()
  }, [])

  const [tempStatus, setTempStatus] = useState<any>([])

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/')
      }),
    )
  }, [])

  /** Get Partner Order Details By Id Success Function */
  const getPartnerOrderDetailsByIdOnSuccess = (data: any) => {   
    const { partnerOrder } = data || {}
    const columns: any = OrderLineListColumn()
    setOrderLineTableColumn(columns)
    setOrderDetails(partnerOrder)
    let temp = '';
    

    switch (partnerOrder?.status) {
      case 'CONFIRMED':
      case 'EXCEPTION':
      case 'BACKORDER':
      case 'FAILED':
      case 'RECEIVED':
        temp = 'Order-Received';
        break;
      case 'OPEN':
      //case 'TOBECONSIGNED':
        temp = 'Pick-pack-in-progress';
        break;
      case 'TOBECONSIGNED':
        temp = 'To-be-consigned';
        break;
      case 'CLOSED':
      case 'PARTIALCONSIGNED':
        temp = 'Shipped';
        break;
      default:
        temp = '-';
        break;
    }

    setTempStatus(temp)
    setWarehouseDetails(data?.warehouse)
    setShippingInformation(data?.shipmentConsignment)
  }

  /** Get Partner Order Details By Id Error Function */
  const getPartnerOrderDetailsByIdOnError = (error: any) => {
    notify({
      alertHeader: 'Error',
      alertBody: error.message ? error.message : 'Something went wrong',
      status: 'Error',
    })
  }

  /** API Call for Get Partner Order Details By Id */
  const {
    isLoading: GetPartnerOrderDetailsByIdIsLoading,
    refetch: callGetPartnerOrderDetailsById,
  } = useGetPartnerOrderDetailsById(
    {
      orderId: searchParams.get('order_id') || '',
    },
    getPartnerOrderDetailsByIdOnSuccess,
    getPartnerOrderDetailsByIdOnError,
  )

  return (
    <div className='relative' {...restProps}>
      
      <div className='rounded-lg p-3 shadow-md bg-white'>
      <div className='pl-4 pb-2'>  
          <span className='text-[#F37021] font-bold' style={{ fontSize: '1.4rem' }}>#{orderDetails.customerOrderNo || 'Loading...'}</span>
      </div>

        {GetPartnerOrderDetailsByIdIsLoading && <PageLoader />}
        <OrderStatusProgressBar
          currentStatus={tempStatus || '-'}
          partnerOrderHistory={orderDetails?.partnerOrderHistory || []}
        />
 
        <OrderInformationSection
          orderDetails={orderDetails}
          warehouseDetails={warehouseDetails}
          shippingInformation={shippingInformation}
        />

        <div className='font-semibold text-base text-N-800 mt-4 pb-2 underline'>
          Order Details
        </div>

        <div
          className={CN('w-1/2 mb-2 relative h-[calc(80vh-350px)]', {
            '!h-auto':
              orderDetails?.partnerOrderLines &&
              orderDetails?.partnerOrderLines?.length > 0,
          })}>
          <div
            className={CN(
              'styled-scroll h-[calc(100%-8px)] overflow-auto w-full bg-white',
            )}>
            <UITable
              data={orderDetails?.partnerOrderLines || []}
              isLoading={GetPartnerOrderDetailsByIdIsLoading}
              className=''
              columns={orderLineTableColumn || []}
              allowRowSelection={false}
              isSorted={true}
              isPaginated={false}
              isManualSortBy={false}
              hasFooter={false}
              hasCheckBox={false}
              isHeader={true}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              footerRow={
                <tr>
                  <td className='!pl-[24px] font-medium text-xs'>TOTAL</td>
                  <td className='!pl-[24px] font-medium text-xs'>
                    {orderDetails?.partnerOrderLines?.reduce(
                      (sum: number, row: OrderLine) => sum + (row.qty || 0),
                      0
                    )}
                  </td>
                  <td className='!pl-[24px] font-medium text-xs'>
                    {orderDetails?.partnerOrderLines?.reduce(
                      (sum: number, row: OrderLine) => sum + (row.shippedQTY || 0),
                      0
                    )}
                  </td>
                </tr>
              }
            />
          </div>
        </div>
      </div>
    </div>    
  )
}

OrderDetails.defaultProps = {
  className: undefined,
}

export default OrderDetails
