/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import CN from 'classnames'
import {
  AUSPOST,
  AUSPOSTINTERNATIONAL,
  DIRECT,
  SMARTSEND,
} from 'static-data/courierList'

import { courierNameConversion } from 'utils'

export interface OrderInformationSectionProps {
  [x: string]: any
  className?: string | undefined
}

export const OrderInformationSection: FC<OrderInformationSectionProps> = ({
  className,
  orderDetails,
  shippingInformation,
  warehouseDetails,
  ...restProps
}: OrderInformationSectionProps) => {
  const OrderStatusProgressBarClassnames = CN(
    'rounded border-[1px] border-N-200 mt-8 p-3',
    className,
  )

  return (
    <div className={OrderStatusProgressBarClassnames} {...restProps}>
          {/* Shipper & Receiver Details Side by Side */}
      <div className='grid grid-cols-2 gap-6'>
        {/* Shipper Details */}
        <div className='bg-gray-100 pt-4 rounded-lg shadow-sm'>
          <div className='border-[1px] border-P-200 bg-P-50 rounded mb-3'>
            <span className='font-medium text-base text-n-600 pl-2 py-1'>Shipper Details:</span>
          </div>
          {/*<h3 className='text-sm font-semibold text-gray-700 mb-3'>Shipper Details</h3>*/}
          <div className='space-y-2 pl-2 pr-2'>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Name:</strong> {warehouseDetails?.name || 'N/A'}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Address:</strong> {warehouseDetails?.address?.lineOne}, {warehouseDetails?.address?.suburb}, {warehouseDetails?.address?.state}, {warehouseDetails?.address?.postCode}, {warehouseDetails?.address?.country}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Phone:</strong> {warehouseDetails?.phoneNo || 'N/A'}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Email:</strong> <a href={`mailto:${warehouseDetails?.email}`} className='text-blue-600 hover:underline'>{warehouseDetails?.email || 'N/A'}</a></p>
          </div>
        </div>

        {/* Receiver Details */}
        <div className='bg-gray-100 pt-4 rounded-lg shadow-sm'>
          <div className='border-[1px] border-P-200 bg-P-50 rounded mb-3'>
            <span className='font-medium text-base text-n-600 pl-2 py-1'>Receiver Details:</span>
          </div>
          {/*<h3 className='text-sm font-semibold text-gray-700 mb-3'>Receiver Details</h3>*/}
          <div className='space-y-2 pl-2 pr-2'>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Name:</strong> {orderDetails?.customerFirstName} {orderDetails?.customerLastName}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Address:</strong> {orderDetails?.shippingAddress?.lineOne}, {orderDetails?.shippingAddress?.lineTwo}, {orderDetails?.shippingAddress?.suburb}, {orderDetails?.shippingAddress?.state}, {orderDetails?.shippingAddress?.postCode}, {orderDetails?.shippingAddress?.country}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Phone:</strong> {orderDetails?.customerPhone || 'N/A'}</p>
            <p className='text-sm text-gray-700'><strong className='font-medium'>Email:</strong> <a href={`mailto:${orderDetails?.customerEmail}`} className='text-blue-600 hover:underline'>{orderDetails?.customerEmail || 'N/A'}</a></p>
          </div>
        </div>
      </div>


      <div className='border-[1px] border-P-200 bg-P-50 rounded mt-6'>
        <span className='font-medium text-base text-n-600 pl-2 py-1'>
          Carrier Details:
        </span>
      </div>

      {shippingInformation?.length === 0 && (
        <div className='grid grid-cols-4 pt-4 pl-2 pr-2'>
          <div className='flex flex-col '>
            <span className='font-regular text-base text-N-500'>Courier</span>

            <span className='font-medium text-base text-N-800 pt-1'>N/A</span>
          </div>

          <div className='flex flex-col'><span className='font-regular text-base text-N-500'>Service Type</span>
            <span className='font-medium text-base text-N-800 pt-1'>N/A</span>
          </div>

          <div className='flex flex-col'>
            <span className='font-regular text-base text-N-500 '>
              Tracking ID
            </span>

            <span className={CN('font-medium text-base  pt-1 !text-N-800')}>
              N/A
            </span>
          </div>
          <div className="flex flex-col">
              <span className="font-regular text-base text-N-500">Weight</span>
              <span className="font-medium text-base text-N-800 pt-1">N/A</span>
           </div>
        </div>
      )}

      {shippingInformation?.length > 0 && shippingInformation?.map((item: any, index: number) => (
          <div className='flex flex-col' key={index}>
            <div className='grid grid-cols-4 pt-4'>
              <div className='flex flex-col'>
                <span className='font-medium text-base text-N-500 pl-2'>
                  Courier
                </span>

                <span className='font-regular text-base text-N-800 pt-1 pl-2'>
                  {item?.type ? courierNameConversion(item?.type) : 'N/A'}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='font-medium text-base text-N-500'>
                  Service Type
                </span>

                <span className='font-regular text-base text-N-800 pt-1'>
                  {item?.productName ? item?.productName : 'N/A'}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='font-medium text-base text-N-500 '>
                  Tracking ID
                </span>

                <span className='font-regular text-base text-N-800 pt-1'>
                  {item?.trackingCode ? item?.trackingCode : 'N/A'}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='font-medium text-base text-N-500 '>
                  Weight
                </span>

                <span className='font-regular text-base text-N-800 pt-1'>
                  {item?.weight ? item?.weight : 'N/A'}
                </span>
              </div>
            </div>
          </div>
      ))}
    </div>
  )
}

OrderInformationSection.defaultProps = {
  className: undefined,
  currentStatus: undefined,
}

export default OrderInformationSection