/* Layouts */
import { AuthLayout } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import {
  ForgotPassword,
  LoginFrom,
  ResetPassword,
  SuccessResetPassword,
} from 'components/pages/auth'
import { Error404 } from 'components/pages/Error404'
import { OrderDetails, OrderList } from 'components/pages/Order'
import { Profile } from 'components/pages/Profile'
import { InboundOrderLineReport } from 'components/pages/reports/InboundOrderLineReport'
import { Reports } from 'components/pages/reports/Reports'

import { ComingSoon } from "../components/pages/CommingSoon";

/* Page components */

interface Route {
  path: string
  component?: any
  heading?: string | any
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
  isBackButton?: string
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: LoginFrom,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/forgot-password',
    component: ForgotPassword,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/reset-password',
    component: ResetPassword,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/success-reset-password',
    component: SuccessResetPassword,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  // {
  //   path: '/',
  //   component: Dashboard,
  //   layout: MainLayout,
  //   heading: 'Dashboard',
  //   isSecured: true,
  // },
  {
    path: '/',
    component: OrderList,
    layout: MainLayout,
    heading: 'Orders',
    isSecured: true,
  },
  {
    path: '/order-details',
    component: OrderDetails,
    layout: MainLayout,
    heading: 'Order',
    isSecured: true,
    isBackButton: 'BACK TO ORDER LIST',
  },
  {
    path: '/inbound/inbound-shipment',
    component: ComingSoon, //InboundShipmentList,
    layout: MainLayout,
    heading: 'Inbound Shipments',
    isSecured: true,
  },
  {
    path: '/inventory/stock-on-hand',
    component: ComingSoon, //StockOnHandList,
    layout: MainLayout,
    heading: 'Stock On Hand',
    isSecured: true,
  },
  {
    path: '/profile',
    component: Profile,
    layout: MainLayout,
    heading: 'Profile',
    isSecured: true,
  },
  {
    path: '/reports',
    component: Reports,
    layout: MainLayout,
    heading: 'Reports',
    isSecured: true,
  },
  {
    path: '/reports/inbound-order-line-report',
    component: InboundOrderLineReport,
    layout: MainLayout,
    heading: 'Reports',
    isSecured: true,
    isBackButton: 'BACK TO REPORTS',
  },
  {
    path: '*',
    component: Error404,
    isSecured: true,
  },
]

export default routes
