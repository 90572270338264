 export const CONFIRMED = 'CONFIRMED' //CONFIRMED, EXCEPTION, BACKORDER, FAILED,RECEIVED--> Received-Order Received
 export const OPEN = 'OPEN'         //  TOBECONSIGNED,OPEN,Pick completed,Picking progress--> Pick & pack in progress
 export const TOBECONSIGNED = 'TOBECONSIGNED'//TOBECONSIGNED  - To be consigned
 export const CLOSED = 'CLOSED'    // CLOSED, PARTIALCONSIGNED - Shipped  
 export const ONHOLD = 'ONHOLD'   //ONHOLD 
export const CANCELLED = 'CANCELLED' //CANCELLED -->Cancelled
 export const PARTIALCONSIGNED = 'PARTIAL-CONSIGNED'
 export const EXCEPTION = 'EXCEPTION'
export const FAILED = 'FAILED'
export const BACKORDER = 'BACKORDER'
 export const RECEIVED = 'RECEIVED'



