export const OrderLineListColumn = () => {
  const COLUMNS = [
    {
      Header: 'SKU',
      accessor: 'partnerSku',
      forSorting: 'partnerSku',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='w-full cursor-pointer'>{original.partnerSku}</div>
        )
      },
    },
    // {
    //   Header: 'ITEM',
    //   accessor: 'name',
    //   forSorting: 'name',
    //   isSortable: true,
    //   hasFilter: true,
    //   isVisible: true,
    //   commonClassName: 'w-full min-w-[112px] pl-6',
    //   cellClassName: 'flex items-center justify-start',
    //   Cell: (props: any) => {
    //     const {
    //       row: { original },
    //     } = props || {}

    //     return <div>{original?.name ? original?.name : 'N/A'}</div>
    //   },
    // },
    {
      Header: 'ORD QTY',
      accessor: 'qty',
      forSorting: 'qty',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.qty ? original?.qty : 'N/A'}</div>
      },
    },
    // {
    //   Header: 'WAREHOUSE PRODUCT',
    //   accessor: 'warehouseSku',
    //   forSorting: 'warehouseSku',
    //   isSortable: true,
    //   hasFilter: true,
    //   isVisible: true,
    //   commonClassName: 'w-full min-w-[150px] pl-6',
    //   cellClassName: 'flex items-center justify-start',
    //   Cell: (props: any) => {
    //     const {
    //       row: { original },
    //     } = props || {}

    //     return <div>{original?.warehouseSku}</div>
    //   },
    // },
    {
      Header: 'SHIPPED QTY',
      accessor: 'shippedQTY',
      forSorting: 'shippedQTY',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[70px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>{original?.shippedQTY ? original?.shippedQTY : 'N/A'}</div>
        )
      },
    },    
  ]

  


  return COLUMNS
}

export default OrderLineListColumn
