import React, { FC } from 'react'
import CN from 'classnames'
import {
  BACKORDER,
  CANCELLED,
  CLOSED,
  CONFIRMED,
  EXCEPTION,
  FAILED,
  ONHOLD,
  OPEN,
  PARTIALCONSIGNED,
  RECEIVED,
  TOBECONSIGNED,
} from 'static-data/orderStatus'

import { Badge } from 'components/atoms'
import { capitalizeAll, orderStatusNameConversion } from 'utils'

interface OrderStatusBadgeProps {
  className?: string | undefined
  orderStatus?: string | undefined
}

export const OrderStatusBadge: FC<OrderStatusBadgeProps> = ({
  className,
  orderStatus,
  ...restProps
}: OrderStatusBadgeProps) => {
  /** main badge styles */
  const OrderStatusBadgeClassName = CN(
    '',
    {     
      '!bg-G-500': orderStatus === CONFIRMED || orderStatus === EXCEPTION || orderStatus === BACKORDER || orderStatus === RECEIVED || orderStatus === FAILED, //CONFIRMED, EXCEPTION, BACKORDER, RECEIVED, FAILED
      '!bg-[#7DB2FF]': orderStatus === OPEN ,
      '!bg-[#1C78FF]': orderStatus === TOBECONSIGNED,
      '!bg-N-700': orderStatus === ONHOLD,
      '!bg-S-700': orderStatus === CANCELLED,
      '!bg-[#227E02]': orderStatus === CLOSED ||orderStatus === PARTIALCONSIGNED,
    },
    className,
  )

  return (
    <Badge
      className={OrderStatusBadgeClassName}
      isRounded
      type='default'
      {...restProps}>
      {orderStatusNameConversion(capitalizeAll(orderStatus))}
    </Badge>
  )
}

export default OrderStatusBadge

OrderStatusBadge.defaultProps = {
  className: undefined,
  orderStatus: undefined,
}
