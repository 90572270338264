import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import bdlLogo from 'assets/images/bdl-logo.svg'
import CN from 'classnames'


export interface HeaderProps {
  className?: string | undefined
  [x: string]: any
}

export const Header: FC<HeaderProps> = ({
  className,
  ...restProps
}: HeaderProps) => {
  const { userDetails }: any = useSelector((state: any) => state.user) 
  const partnerName = userDetails?.partnerName || ''
  const userName = `Hi ${userDetails?.firstName || ''}`.trim()

  const HeaderClasses = CN(
    'flex justify-between items-center w-full border-b-[1px] border-[#E5E7EB] py-2 relative px-4',
    className,
    {}
  )

  return (
    <div className={HeaderClasses} {...restProps}>
      <div className="flex items-center gap-4">
        <img src={bdlLogo} alt="BDL Logo" className="object-contain h-8 ml-2" />
        <span className="text-lg font-medium">{partnerName}</span>
      </div>
      <span className="text-lg font-medium pr-7">{userName}</span>
    </div>
  )
}

Header.defaultProps = {
  className: undefined
}

export default Header
